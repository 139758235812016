<template>
  <div
    class="login-aside d-flex flex-column flex-row-auto"
    style="background-color: #F2C98A;"
  >
    <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
      <a href="#" class="text-center mb-10">
        <img
          src="media/logos/logo-text-ifad-preto.png"
          class="max-h-70px"
          alt=""
        />
      </a>
      <h3
        class="font-weight-bolder text-center font-size-h4 font-size-h1-lg px-15"
        style="color: #986923;"
      >
        Aqui você pode elaborar seu Plano de Investimento com uma ferramento
        intuitiva que lhe ajudará a crescer em seu negócio.
      </h3>
    </div>
    <div
      class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Aside",
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    }
  }
};
</script>
